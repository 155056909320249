(function(){"use strict";class h{constructor(){this.data={}}set(t,e){this.data[t]=e}get(t){return this.data[t]}}const v={Booster:class{constructor(t){this.options=t}log(t,e){const s=`
      display: inline-block;
      padding: 4px 6px;
      border-radius: 4px;
      line-height: 1.5em;
      color: #282735;
      background: linear-gradient(45deg,
        rgba(185, 205, 255, 0.4) 0%,
        rgba(201, 182, 255, 0.4) 33%,
        rgba(239, 184, 255, 0.4) 66%,
        rgba(255, 210, 177, 0.4) 100%);
        `,i=[`%c[${this.options.title}] ${t}. Link to documentation ${this.options.documentationLink}`,s];e?(console.group(...i),Array.isArray(e)?console.log(...e):console.log(e),console.groupEnd()):console.log(...i)}validate(t,e,s){if(!t.validate)return!0;if(typeof t.validate=="function"){if(!t.validate(s))return this.log(`Invalid value "${s}" for attribute "${e}"`),!1}else if(!t.validate.includes(s))return this.log(`Invalid value "${s}" for attribute "${e}"`,[`%cPossible values:%c
`+t.validate.map(i=>`• ${i}`).join(`
`),"font-weight: 700;","font-weight: initial;"]),!1;return!0}parse(t){const e=new h;for(const s in this.options.attributes){const i=this.options.attributes[s],a=t.getAttribute(s);if(!a){e.set(s,i.defaultValue);continue}if(!this.validate(i,s,a))continue;let r=a;i.parse&&(r=i.parse(a)??i.defaultValue),e.set(s,r)}this.options.apply.call(this,t,e)}getElements(){return document.querySelectorAll(`[${this.options.name}]`)}init(){this.getElements().forEach(e=>this.parse(e))}},parse:{stringToBoolean:o=>o!=="false"},validation:{isBoolean:o=>/^(true|false)$/.test(o),isNumber:o=>!isNaN(Number(o))}};var g=(o=>(o.Left="left",o.Right="right",o))(g||{});function m(o){const t=Array.from(o.querySelectorAll("[fb-slider-arrow]"));if(!t.length)return;const e=o.querySelector(".w-slider-arrow-left"),s=o.querySelector(".w-slider-arrow-right");if(!e&&!s)return this.log("Webflow Slider Arrows are not detected");const i=[],a=[];for(const r of t){const u=r.getAttribute("fb-slider-arrow"),d=u;Object.values(g).includes(d)?(d==="left"&&i.push(r),d==="right"&&a.push(r)):this.log(`Invalid value "${u}" for attribute "fb-slider-arrow"`,[`%cPossible values:%c
`+Object.values(g).map(n=>`• ${n}`).join(`
`),"font-weight: 700;","font-weight: initial;"])}if(i.length)if(e){e.style.display="none";for(const r of i)r.addEventListener("click",()=>e.click())}else this.log("Webflow Left Arrow isn't detected");if(a.length)if(s){s.style.display="none";for(const r of a)r.addEventListener("click",()=>s.click())}else this.log("Webflow Right Arrow isn't detected")}function p(o){const t=Array.from(o.querySelectorAll("[fb-slider-nav-item]"));if(!t.length)return;const e=Array.from(o.querySelectorAll(".w-slider-nav .w-slider-dot"));if(!e.length)return this.log("Webflow Slider Nav isn't detected");const s="slider-nav-active",i=n=>{const l=t.findIndex(c=>c===n.target);e[l].click()};for(const n of t)n.addEventListener("click",i);const a=["aria-label","aria-pressed","role","tabindex"],r=(n,l)=>{if(!(!n||!l))for(const c of a){const f=n.attributes.getNamedItem(c);f&&l.setAttribute(f.name,f.value)}},u=()=>{const n=t.findIndex(w=>w.classList.contains(s)),l=e.findIndex(w=>w.classList.contains("w-active"));if(n===l)return;const c=t[n],f=t[l];c&&(c.classList.remove(s),r(e[n],c)),f&&(f.classList.add(s),r(e[l],f))},d=new MutationObserver(u);e.forEach((n,l)=>{d.observe(n,{attributeFilter:["class"]}),r(e[l],t[l])}),u()}const A=new v.Booster({name:"fb-slider",attributes:{},apply(o){m.call(this,o),p.call(this,o)},title:"Webflow Custom Slider Booster",documentationLink:"https://www.flowbase.co/booster/webflow-custom-slider"}),b=()=>A.init();document.readyState==="complete"?b():window.addEventListener("load",b)})();
